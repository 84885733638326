import React from "react"
import { Helmet } from "react-helmet"

import Header from "src/components/headers/header"
import Footer from "src/components/footers/footer"
import Form from "src/components/formulaires/formGeneral"


export default function index() {

  return (
    <div className="bg-white">
      <Helmet>
        <title>Koudepouce</title>
        <meta name="description" content="Koudepouce simplifie votre vie au quotidien." />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Header />
      </header>

      <main className="relative">
        <div className="relative bg-white">
          <div className="relative max-w-7xl mx-auto py-8 px-4">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
                Demandes d’informations
              </span>
            </h1>
            <div className="hidden lg:block">
              <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
              Internet regorge de conseils et de réponses. Mais, il est souvent difficile d’identifier ce qui est fiable de ce qui ne l’est pas. Koudepouce simplifie la recherche de solutions via internet, en apportant des réponses claires et documentées aux problématiques du quotidien.
              </p>
              <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
              Koudepouce vous permet aussi d’entrer en contact avec des entreprises de votre choix. Au final, vous obtenez des devis en quelques clics et vous restez libre d’engager qui vous voulez.
              </p>
            </div>
          </div>
        </div>

        <Form />

      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  )
}